/* globals APP_NAME */
import { Fragment } from "react"
import Condition from "@mkt/ui/components/common/Condition"
import loadable from "@loadable/component"
import NoInteractionAlert from "@mkt/ui/components/common/NoInteractionAlert"
import SbEditable from "@mkt/ui/components/common/SbEditable"

import LoaderRedirect from "./components/LoaderRedirect"
import ModalList from "./components/ModalList"

import useProductPage from "./hooks"
import * as S from "./styles"

import "@iq-firebolt/client/dist/main.css"
import { APP_NAME } from "gatsby-env-variables"

const SoftLeadForm = loadable(() => import("./components/SoftLeadForm"))

const ProductPage = ({
  children,
  ComponentCardsFeatured = "",
  ComponentCardTheme = "",
  ComponentContent,
  ComponentHero = "",
  ComponentButton = Fragment,
  pageContext,
  location,
  LogoRedirect,
  showSoftlead = true,
  ...props
}) => {
  const {
    choosenCard,
    content,
    currentPayload,
    dataSortingHat,
    formFocused,
    isOpenLoaderRedirect,
    isOpenSortingHat,
    productFeatured,
    getDataSortinHatBySlug,
    handleSetDataSortingHat,
    setChoosenCard,
    setFormFocused,
    setIsOpenSortingHat,
    setOpenLoaderRedirect,
    fireboltPrerender,
    featuredCardsBox,
    sortingHatDecision,
    setSortingHatDecision,
  } = useProductPage({ pageContext })

  return (
    <SbEditable content={content}>
      <div className="product-page-template" css={S.ProductPageTemplate}>
        <Condition condition={!!ComponentCardTheme}>
          <ComponentCardTheme color={content?.product_theme?.color} />
        </Condition>

        <Condition condition={!!ComponentHero}>
          <ComponentHero content={content} {...props} />
        </Condition>

        <ComponentContent
          location={location}
          content={content}
          {...props}
          isActiveSortingHat={isOpenSortingHat}
          slug={pageContext?.slug}
        >
          {showSoftlead && (
            <SoftLeadForm
              pageContext={pageContext}
              choosenCard={choosenCard}
              dataSortingHat={dataSortingHat}
              getDataSortinHatBySlug={getDataSortinHatBySlug}
              handleSetDataSortingHat={handleSetDataSortingHat}
              currentPayload={currentPayload}
              fireboltPrerender={fireboltPrerender}
              setChoosenCard={setChoosenCard}
              isOpenSortingHat={isOpenSortingHat}
              setIsOpenSortingHat={setIsOpenSortingHat}
              setOpenLoaderRedirect={setOpenLoaderRedirect}
              isOpenLoaderRedirect={isOpenLoaderRedirect}
              LogoRedirect={LogoRedirect}
              content={content}
              ComponentButton={ComponentButton}
              setFormFocused={setFormFocused}
              setSortingHatDecision={setSortingHatDecision}
              sortingHatDecision={sortingHatDecision}
            />
          )}
        </ComponentContent>

        {children}

        <div ref={featuredCardsBox}>
          <Condition
            condition={
              !!ComponentCardsFeatured && pageContext.productsHighlighted
            }
          >
            <ComponentCardsFeatured
              type={content?.type}
              blok={pageContext.productsHighlighted}
            />
          </Condition>
        </div>
      </div>

      <LoaderRedirect
        data={dataSortingHat}
        isOpen={isOpenLoaderRedirect}
        LogoRedirect={LogoRedirect}
      />

      <ModalList
        pageContext={pageContext}
        sortingHatDecision={sortingHatDecision}
        currentPayload={currentPayload}
        handleSetDataSortingHat={handleSetDataSortingHat}
        isOpenSortingHat={isOpenSortingHat}
        setIsOpenSortingHat={setIsOpenSortingHat}
        logoRedirect={LogoRedirect}
      />

      <NoInteractionAlert isModal={true} hasStartedForm={formFocused} />
    </SbEditable>
  )
}

export default ProductPage
