import { useEffect, useState } from "react";
import useStoryblokDimensions from "@mkt/utils/storyblok/useStoryblokDimensions";

const useLoaderRedirect = (data) => {
  const [issuerName, setIssuerName] = useState(null);
  const dimensions = useStoryblokDimensions('issuers');

  useEffect(() => {
    if (data?.issuer && dimensions) {
      const foundIssuer = dimensions.find(dimension => dimension.value === data.issuer);
      if (foundIssuer) {
        setIssuerName(foundIssuer.name);
      }
    }
  }, [data?.issuer, dimensions]);

  return { issuerName };
}

export default useLoaderRedirect;
