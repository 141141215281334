import loadable from "@loadable/component"
import BannerCard from "../BannerCard"

import useCardContent from "./hook"
import * as S from "./styles"

const CardDetails = loadable(() => import("../CardDetails"))

const Content = ({ content, children }) => {
  useCardContent(content)
 
  return (
    <div className="article-content">
      <div css={S.articleContentContainer}>
        <div css={S.articleContentWrap}>
          <BannerCard content={content} />
        </div>
        <div css={S.articleContentWrap}>
          <div css={S.articleContentCard}>
            <CardDetails
              content={content}
            />
          </div>
          <div css={S.articleFormContainer} data-form>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content
