import { useEffect, useState, useRef } from "react"
import { clearFormSession } from "@iq-firebolt/client"
import useStoryblok from "@mkt/utils/storyblok/useStoryblok"
import getUrlParams from "@mkt/utils/getUrlParams"
import { track } from "@mkt/analytics/redline"
import { trackingEventNames } from "@mkt/analytics/constants"
import useAnalytics from "@mkt/ui/hooks/useAnalytics"
import { setUtmParamsLocal } from "@mkt/utils/safeLocalStorage"

const useProductPage = ({ pageContext }) => {
  const [isOpenSortingHat, setIsOpenSortingHat] = useState(false)
  const [sortingHatDecision, setSortingHatDecision] = useState()
  const [isOpenLoaderRedirect, setOpenLoaderRedirect] = useState(false)
  const [dataSortingHat, setDataSortingHat] = useState({})
  const [currentPayload, setCurrentPayload] = useState()
  const [choosenCard, setChoosenCard] = useState()
  const [formFocused, setFormFocused] = useState(false)
  const [hasTrackedProduct, setHasTrackedProduct] = useState(false)
  const [badCredit, setBadCredit] = useState(false)

  const { anonymousId } = useAnalytics()
  const [cardsFeaturedInHtml, setCardsFeaturedInHtml] = useState(false)
  const featuredCardsBox = useRef(null)

  useEffect(() => {
    const observerToDisplayTheHighlightCards = new IntersectionObserver(
      (elem) =>
        elem[0].intersectionRatio !== 0 ? setCardsFeaturedInHtml(true) : null
    )
    if (featuredCardsBox.current)
      observerToDisplayTheHighlightCards.observe(featuredCardsBox.current)
  }, [])

  const data = useStoryblok(pageContext.content)
  const content = data?.content || pageContext.content
  const fireboltPrerender = pageContext.fireboltPrerender

  const productFeatured =
    content?.products_highlighted &&
    content?.products_highlighted.length &&
    content?.products_highlighted[0]

  useEffect(() => {
    setFormFocused(formFocused)
  }, [formFocused])

  useEffect(() => {
    setChoosenCard({ choosen_card: pageContext?.slug })
    const badCreditComponent = pageContext?.content?.integrations_list?.find(
      (item) => item.component === "component_bad_credit"
    )
    setBadCredit(badCreditComponent && badCreditComponent.active === "true")
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (window.analytics) {
        if (!hasTrackedProduct) {
          track(trackingEventNames["PRODUCT_VIEWED"], {
            products: [data],
            listId: "product-page",
          })

          setHasTrackedProduct(true)
        }
      }
    }, 500)
  }, [])

  useEffect(() => {
    if (choosenCard && choosenCard?.choosen_card !== pageContext?.slug) {
      track(trackingEventNames["CHOOSEN_CARD_CLEAR_SESSION"], {
        choosenCard,
        slug: pageContext?.slug,
        anonymousId,
      })

      clearFormSession()
    }
  }, [setChoosenCard])

  useEffect(() => {
    const utmMediumParam = getUrlParams().get("utm_medium")
    const utmCampaignParam = getUrlParams().get("utm_campaign")

    if (utmMediumParam || utmCampaignParam) {
      setUtmParamsLocal({
        utm_medium: utmMediumParam,
        utm_campaign: utmCampaignParam,
      })
    }
  }, [])

  function getDataSortinHatBySlug(slug) {
    return pageContext?.newSortingHatData?.find((item) => item.slug === slug)
  }

  function handleSetDataSortingHat({ slug, payload, preventContinue = false }) {
    const data = getDataSortinHatBySlug(slug)

    setDataSortingHat({ ...data, preventContinue })
    setCurrentPayload(payload)
  }

  return {
    badCredit,
    choosenCard,
    content,
    currentPayload,
    dataSortingHat,
    formFocused,
    getDataSortinHatBySlug,
    handleSetDataSortingHat,
    isOpenLoaderRedirect,
    isOpenSortingHat,
    productFeatured,
    setChoosenCard,
    setFormFocused,
    setIsOpenSortingHat,
    setOpenLoaderRedirect,
    fireboltPrerender,
    cardsFeaturedInHtml,
    featuredCardsBox,
    sortingHatDecision,
    setSortingHatDecision,
  }
}

export default useProductPage
