import { Text, Button } from "iq-blueberry"
import Condition from "@mkt/ui/components/common/Condition"
import Img from "@mkt/ui/components/common/Img"
import { isMobile } from "@mkt/utils/getDevice"
import { noInteractionAlertData } from "@mkt/ui/components/common/NoInteractionAlert/data"

import Modal from "../Modal"

import * as S from "./styles"
import useNoInteractionAlert from "./hook.js"

const NoInteractionAlert = ({ isModal = false, hasStartedForm = false }) => {
  const {
    noInteractionAlertImage,
    noInteractionAlertModalImage,
    imageAltBanner,
    imageAltModal,
    title,
    subtitle,
    buttonText,
    url,
    pathnamesToShowBanner,
    pathnamesToUseModalOnly,
    timeToShow,
  } = noInteractionAlertData

  const { isShowing, onClickButtonBanner, onClickButtonModal } =
    useNoInteractionAlert({ timeToShow, url, hasStartedForm })
  const styleShowBanner = isShowing
    ? S.container
    : [S.container, S.containerHidden]

  const showNoInteractionAlert =
    isMobile() && pathnamesToShowBanner.indexOf(location.pathname) > -1

  const filterShowNoInteractionAlert = (value) => {
    const regex = new RegExp(value)
    const pathname = location.pathname

    return regex.test(pathname)
  }
  const showNoInteractionAlertModalOnly =
    isMobile() &&
    pathnamesToUseModalOnly?.filter(filterShowNoInteractionAlert).length > 0

  const image = isModal ? noInteractionAlertModalImage : noInteractionAlertImage
  const imageAlt = isModal ? imageAltModal : imageAltBanner
  const onClickButton = isModal ? onClickButtonModal : onClickButtonBanner

  const content = (
    <div css={isModal ? [S.container, S.modal] : styleShowBanner}>
      <div css={S.image}>
        <figure css={isModal ? [S.figure, S.figureModal] : S.figure}>
          <Img
            src={image}
            loading="eager"
            alt={imageAlt}
            width={isModal ? "150px" : "140px"}
            height={isModal ? "193px" : "198px"}
          />
        </figure>
      </div>
      <div css={S.data}>
        <Text as="h2" variant="heading-two" css={S.title}>
          {title}
        </Text>
        <Text as="h3" defaultColor="common" css={S.subtitle}>
          {subtitle}
        </Text>
        <Button onClick={onClickButton}>{buttonText}</Button>
      </div>
    </div>
  )

  const hideAlert = !showNoInteractionAlert && !showNoInteractionAlertModalOnly
  return isModal ? (
    <Condition condition={!hideAlert}>
      <Modal
        containerCss={
          isShowing
            ? S.modalContainer
            : [S.modalContainer, S.modalContainerHidden]
        }
      >
        {content}
      </Modal>
    </Condition>
  ) : (
    <Condition condition={!hideAlert}>{content}</Condition>
  )
}
export default NoInteractionAlert
