import { useState, useEffect } from "react"

const useModal = ({openOnStart, useTimerToOpen, timeToOpenModal}) => {
	const [isOpen, setIsOpen] = useState(false)
  const [timeoutObject, setTimeoutObject] = useState(false)

  useEffect(() => {
    if (useTimerToOpen) {
      const timeoutCreated = setTimeout( () => {
        openModal()
      }, timeToOpenModal)
      
      setTimeoutObject(timeoutCreated)
    } 
    if (openOnStart) {
      openModal()
    }
  }, [])

  function removeTimeout() {
    if(timeoutObject) {
      clearTimeout(timeoutObject)
    }
  }

  function closeModal(e) {
    e.preventDefault()

    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return {
    isOpen,
    openModal,
    closeModal,
    removeTimeout,
  }
}

export default useModal
