import isDev from "@mkt/utils/isDev"
import getSortingHatUrlParams from "@mkt/utils/getSortingHatUrlParams"

const formatMobiUrl = ({
  cardId,
  externalId = 'no-externa-id',
  cardSlug,
  cardType,
  randomHash,
  anonymousId,
  token = 'no-token',
  fireboltId,
  isSortingHat = false,
}) => {
    return formatMobiUrl_NoSkipper({
      cardId,
      externalId,
      cardSlug,
      cardType,
      randomHash,
      anonymousId,
      token,
      fireboltId,
      isSortingHat,
    })

  //const domains = {
    //prd: {
      //itaucard: "https://cartoes.itau.com.br/form/step/personal",
      //credicard: "https://cartoes.credicard.com.br/form/step/personal",
    //},
    //dsv: {
      //itaucard: "https://stg-itaucard-cartoes.br.originhosting.io/form/step/personal",
      //credicard:
        //"https://stg-credicard-cartoes.br.originhosting.io/form/step/personal",
    //},
  //}

  //const sortingHatParams = isSortingHat ? `${getSortingHatUrlParams({fireboltId, anonymousId, forceHasParameters: true})}` : ''

  //const params = `?card=${cardId}&proposal=${externalId}&token=${token}&utm_source=iq360&cpg_s=vitrine_iq&utm_medium=review-form&utm_campaign=review-${cardSlug}&iq_aid=${anonymousId}&iq_tid=${fireboltId}&iq_sid=${anonymousId}&${randomHash}${sortingHatParams}`
  //const path = isDev ? domains["dsv"][cardType] : domains["prd"][cardType]
  //const url = `${path}${params}`

  //return externalId
    //? url
    //: url
        //.replace(/\s+/g, "")
        //.replace("step=1", "step=0")
        //.replace("&proposal=undefined", "")
}

/**
 * @kcalixto
 * We're planning to deprecate itau skipper due to approval_rate issues
 * This is a temp function to test if we can avoid the skipper and still have our tracking params in the url on the conversion api (iq_tid or iq_aid).
 * @see https://github.com/AcordoCertoBR/iq-horcrux/blob/e2fd94e4e8b803d4597b52d69e5cc23ce8841c25/services/itau/api/card/firebolt-webhook/service/steps/personalData.go
 * @returns {boolean}
 */
export const shouldAvoidSkipper = (cardId) => {
  // const uniclassBlack = "221"
  // const uniclass = "216"
  // const cardsToAvoid = [uniclassBlack, uniclass]

  // return cardsToAvoid.includes(cardId)
  return true
}

/**
 * @kcalixto
 * We're planning to deprecate itau skipper due to approval_rate issues
 * This is a temp function to test if we can avoid the skipper and still have our tracking params in the url on the conversion api (iq_tid or iq_aid).
 * @see https://github.com/AcordoCertoBR/iq-horcrux/blob/e2fd94e4e8b803d4597b52d69e5cc23ce8841c25/services/itau/api/card/firebolt-webhook/service/steps/personalData.go
 * @returns {string}
 */
const formatMobiUrl_NoSkipper = ({
  cardId,
  externalId = "no-externa-id",
  cardSlug,
  cardType,
  randomHash,
  anonymousId,
  token = "no-token",
  fireboltId,
  isSortingHat = false,
}) => {
  const domains = {
    prd: {
      itaucard: "https://cartoes.itau.com.br/cartoes",
      credicard: "https://cartoes.credicard.com.br/cartoes",
    },
    dsv: {
      itaucard: "https://stg-itaucard-cartoes.br.originhosting.io/cartoes",
      credicard: "https://stg-credicard-cartoes.br.originhosting.io/cartoes",
    },
  }

  const params = `/${cardId}?utm_source=iq360&cpg_s=vitrine_iq&utm_medium=review-form&utm_campaign=review-${cardSlug}&iq_aid=${anonymousId}&iq_tid=${fireboltId}&iq_sid=${anonymousId}&${randomHash}`
  const path = isDev ? domains["dsv"][cardType] : domains["prd"][cardType]
  const url = `${path}${params}`

  return url
}

export default formatMobiUrl
