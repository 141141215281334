import { Button, Text } from "iq-blueberry"
import OutlineArrowLeft from "iq-blueberry/dist/reactIcons/OutlineArrowLeft"
import OutlineArrowLeftOne from "iq-blueberry/dist/reactIcons/OutlineArrowLeftOne"
import OutlineClose from "iq-blueberry/dist/reactIcons/OutlineClose"
import classNames from "classnames"
import Condition from "@mkt/ui/components/common/Condition"

//Components
import Img from "@mkt/ui/components/common/Img"
import LoaderRedirect from "../LoaderRedirect"

//Assets
import ErrorSearchIcon from "@mkt/ui/images/error-search.svg"

//Hooks
import { useModalList } from "./hook"

import * as S from "./styles"

const ModalList = ({
  pageContext,
  currentPayload,
  isOpenSortingHat,
  sortingHatDecision,
  setIsOpenSortingHat,
  handleSetDataSortingHat,
  logoRedirect,
}) => {
  const {
    sortingHatData,
    chosenCardTitle,
    chosenCardSlug,
    jeittoValue,
    handleRedirectOffer,
    goBackToChooseAnotherCard,
    isOpenLoaderRedirect,
    loaderRedirectData,
  } = useModalList({
    pageContext,
    sortingHatDecision,
    currentPayload,
    handleSetDataSortingHat,
    isOpenSortingHat,
  })

  return (
    <div
      css={
        isOpenSortingHat
          ? [S.LoaderRedirect, S.LoaderRedirectIsOpen]
          : S.LoaderRedirect
      }
    >
      <div
        data-modal-scroll
        css={
          isOpenSortingHat
            ? [S.LoaderRedirectHolder, S.LoaderRedirectHolderActive]
            : S.LoaderRedirectHolder
        }
      >
        <div css={S.ModalSHListClose}>
          <div onClick={() => setIsOpenSortingHat(!isOpenSortingHat)}>
            <OutlineClose />
          </div>
        </div>

        <div css={S.ModalSHListHeader}>
          <div css={S.LowerApproveChance}>
            <Img src={ErrorSearchIcon} alt="Ícone de termometro" />

            <p className="iq-text iq-text--body-small">
              Ops, vimos que{" "}
              <strong>suas chances de aprovação são baixas</strong> no Cartão{" "}
              {chosenCardTitle}
            </p>
          </div>

          <div css={S.FireOffersMessage}>
            <span>🔥</span>

            <p>Você tem mais chances de conseguir essas ofertas</p>
          </div>
        </div>

        <div data-modal-scroll css={S.ModalSHListOptions}>
          {sortingHatData?.map((shCard, index) => (
            <div
              data-scroll="productViewed"
              data-position={index}
              key={shCard?.slug}
              className={classNames("modal-list__item", {
                isSelected: true,
              })}
            >
              <section className="modal-list__card">
                <div>
                  <div className="modal-list__figure">
                    <Img
                      src={shCard?.image_logo?.filename}
                      alt={shCard?.image_logo?.alt}
                      width={80}
                      optimize={false}
                    />
                  </div>

                  <div className="option-details">
                    <div className="option-details__title">
                      <Text variant="heading-six" defaultColor="common">
                        {shCard?.title}
                      </Text>
                      <Condition
                        condition={shCard?.slug === "jeitto" && jeittoValue > 0}
                      >
                        <Text variant="heading-six">
                          Valor pré-aprovado:{" "}
                          {Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(jeittoValue)}
                        </Text>
                      </Condition>
                    </div>
                    {shCard?.feature_information && (
                      <div className="approve-rate-badge">
                        <span className="badge badge--high">
                          {shCard?.feature_information}
                        </span>
                      </div>
                    )}
                    <div className="options-details-advantages">
                      {shCard?.highlights_iq?.map((highlight) => (
                        <div className="advantage" key={highlight?._uid}>
                          <Img
                            src={highlight?.icon?.filename}
                            alt={highlight?.icon?.alt}
                            width={22}
                          />

                          <Text variant="body-small" defaultColor="common">
                            {highlight?.description}
                          </Text>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <Button
                  Icon={OutlineArrowLeftOne}
                  iconRight
                  expand="x"
                  size="medium"
                  justify="center"
                  onClick={() => handleRedirectOffer(shCard)}
                >
                  {shCard?.button_text}
                </Button>
              </section>
            </div>
          ))}
        </div>

        <div css={S.ModalSHListFooter}>
          <div className="modal-list__card-info-buttons">
            <Button
              type="text"
              size="medium"
              expand="x"
              justify="center"
              onClick={() => handleRedirectOffer(pageContext?.content)}
            >
              Continuar com o cartão inicial
            </Button>

            <Button
              Icon={OutlineArrowLeft}
              expand="x"
              size="medium"
              justify="center"
              type="secondary"
              onClick={goBackToChooseAnotherCard}
            >
              Ver mais cartões
            </Button>
          </div>
        </div>
      </div>

      <LoaderRedirect
        data={loaderRedirectData}
        isOpen={isOpenLoaderRedirect}
        LogoRedirect={logoRedirect}
        imgOptimized={false}
      />
    </div>
  )
}

export default ModalList
