import { colors } from "iq-blueberry"
import { css } from "@emotion/react"
import { getDuration } from "@mkt/ui/styles/Functions"
import { above } from "@mkt/ui/styles/Breakpoints"

export const Modal = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  border-radius: 4px;
  padding: 28px 24px;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-overflow-scrolling: touch;
  transition: opacity ${getDuration(1)} ease, visibility ${getDuration(1)} ease;
  position: fixed;
  z-index: 10;

  ${above["m"]} {
    padding: 32px;
  }

  ${above["l"]} {
    padding: 32px 70px;
  }
`

export const ModalActive = css`
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition: opacity ${getDuration(1)} ease, visibility ${getDuration(1)} ease;
`

export const Overlay = css`
  background-color: ${colors["gray-100"]};
  left: 0;
  height: 100%;
  opacity: 0.72;
  position: absolute;
  transition: opacity .3s ease-in-out;
  top: 0;
  width: 100%;
`

export const ContentWrapper = css`
  position: absolute;
  background-color: transparent;
`

export const ContentClose = css`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: ${colors["blueberry-10"]};
  position: absolute;
  top: -12px;
  right: 4px;

  .iq-icon {
    width: 16px;
    height: 16px;
    margin: 4px;
    color: ${colors["blueberry-50"]};
    cursor: pointer;
  }
`