import { useEffect } from "react"

import useAnalytics from "@mkt/ui/hooks/useAnalytics.js"

const useBannerCard = (content) => {
	const { product } = useAnalytics()

  useEffect(() => {
    product.setProduct(content)
  }, [])

  const handleScrollToForm = (e) => {
    e.preventDefault()
    document
      .querySelector(".form-flow__container")
      .scrollIntoView({ behavior: "smooth" })
    const firstInput = document.querySelectorAll(
      'form input[class*="iq-input"]'
    )[0]
    firstInput?.focus()
  }

  return {
	handleScrollToForm
  }
}

export default useBannerCard