import getUrlParams from "./getUrlParams"
import { getUtmParamsLocal } from "./safeLocalStorage"

const getRandomHashMobi = (utms) => {
  const utmLocalStorage = getUtmParamsLocal()

  const currentUtmMedium =
    getUrlParams().get("utm_medium") || utmLocalStorage?.utm_medium || "organic"
  const currentUtmCampaign =
    getUrlParams().get("utm_campaign") ||
    utmLocalStorage?.utm_campaign ||
    "default"

  const hashs = utms[currentUtmMedium]
    ? utms[currentUtmMedium][currentUtmCampaign]
      ? utms[currentUtmMedium][currentUtmCampaign]
      : utms[currentUtmMedium]["default"]
    : ""

  const ramdomHashs = hashs[Math.floor(Math.random() * hashs.length)]
  return ramdomHashs
}

export default getRandomHashMobi
