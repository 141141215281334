import { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useTrack } from "@mkt/analytics/redline/track"

const useNoInteractionAlert = ({timeToShow, url, hasStartedForm}) => {
  const [isShowing, setIsShowing] = useState(false)
  const [timeoutObject, setTimeoutObject] = useState(false)

  const { track } = useTrack()

  useEffect(() => {
    const timeoutCreated = setTimeout( () => {
      showBanner()
    }, timeToShow)

    setTimeoutObject(timeoutCreated)
  }, [])

  useEffect(() => {
    if(hasStartedForm){
      removeTimeout()
    }
  }, [hasStartedForm])

  function removeTimeout() {
    if(timeoutObject) {
      clearTimeout(timeoutObject)
    }
  }

  function hideBanner(e) {
    e.preventDefault()

    setIsShowing(false)
  }

  function showBanner() {
    setIsShowing(true)
  }

  function onClickButton(linkType) {
    track('elementClicked', {
      actionOutcome: 'Redirect para lista de destaques',
      outboundUrl: url,
      webElement: {
        elementType: linkType,
        name: 'Featured Cards Banner',
      }
    })
    navigate(url)
  }

  function onClickButtonBanner(e) {
    e.preventDefault()

    onClickButton('banner')
  }

  function onClickButtonModal(e) {
    e.preventDefault()

    onClickButton('modal')
  }

  return {
    isShowing,
    showBanner,
    hideBanner,
    removeTimeout,
    onClickButtonBanner,
    onClickButtonModal,
  }
}

export default useNoInteractionAlert
