import { propsPresets } from "@iq-firebolt/br-addons"
import { createFireboltProvider } from "@iq-firebolt/client"
import isDev from "@mkt/utils/isDev"

const formNames = {
  iqMarketplace: 'iq-marketplace',
  iqMarketplaceMultistep: 'iq-marketplace-multistep',
  iqWidgetCard: 'widget-card',
  iqMobiMarketplace: 'iq-itau-marketplace',
  iqItauTudoazulTestMarketplace: 'iq-itau-tudoazul-test-ab',
  iqDigioCards: 'digio-cards',
}

const getConfigFirebolt = ({ formName }) => ({
  partnerName: "Iq Marketplace",
  parterSlug: formName,
  experienceType: "credit",
  firebolt: {
    formAccess: {
      root: isDev
        ? "https://dsv-firebolt-api.iq.com.br/"
        : "https://firebolt-api.iq.com.br/",
      formName,
    },
    enforceNewSession: true,
    debug: isDev,
    withHistory: false,
    stepQueryParam: "passo",
    addons: { uiPropsPresets: [propsPresets] },

  },
})

const configs = Object.keys(formNames).reduce((acc, curr) => ({
  ...acc, [curr]: getConfigFirebolt({ formName: formNames[curr] })
}), {})

const withFormMarketplace = createFireboltProvider(configs['iqMarketplace'].firebolt)
const withFormMarketplaceMulti = createFireboltProvider(configs['iqMarketplaceMultistep'].firebolt)
const withFormWidget = createFireboltProvider(configs['iqWidgetCard'].firebolt)
const withFormMobi = createFireboltProvider(configs['iqMobiMarketplace'].firebolt)
const withFormItauTudoazulTest = createFireboltProvider(configs['iqItauTudoazulTestMarketplace'].firebolt)
const withFormDigio = createFireboltProvider(configs['iqDigioCards'].firebolt)

export {
  withFormMarketplace,
  withFormMarketplaceMulti,
  withFormWidget,
  withFormMobi,
  withFormItauTudoazulTest,
  withFormDigio,
}


