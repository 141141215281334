const colors = {
  white: "#fff",
  red: "#EB001B",
  orange: "#CF4500",
  yellow: "#F79E1B",
  neutral: {
    neutral_100: "#F9F9F9",
  },
  steelblue: "#5B6770",
  gray: {
    lighter: "#D0D3D4",
    light: "#F9F9F9",
    medium_light: "#B1ADA6",
    medium_light_2: "#444340",
    medium: "#323231",
    dark_lighter: "#222221",
    dark: "#141413",
    warm: "#e3dfd7",
  },
  cyan: {
    cyan_60: "#0086A6",
  },
  blueberry: {
    blueberry_60: "#453AD3",
  },
  white: "#FFF",
}

const fonts = {
  fontSize: {
    default: "16px",
    sm: "13px",
    lg: "18px",
    xl: "22px",
  },
  fontWeight: {
    extraLight: "200",
    light: "300",
    normal: "400",
    medium: "600",
    bold: "700",
    extrabold: "900",
  },
  fontFamily: {
    mulish: "'Mulish', sans-serif",
    lexend: "'Lexend', sans-serif",
    markForMc: "'MarkForMC', sans-serif",
    markforMCNrw: "'MarkForMCNrw', sans-serif",
  },
}

export { colors, fonts }
