const colors = {
  gray: {
    default: "#656565",
    dark: "#323232",
    medium: "#9B9B9B",
    light: "#E1E1E1",
    lightest: "#F9F9F9",
    lightest_f5: "#f5f5f5",
    gray_50: "#666666",
    gray_60: "#444444"
  },
  black: "#000",
  white: "#fff",
  red: "#FF4747",
  orange: "#FBA925",
  blue: {
    default: "#0504EB",
    dark: "#01006A",
    light: "#12C8F9",
  },
  green: {
    default: "#43CA4D",
    light: "#68E319",
    dark: "#2C6F8F",
  },
}

const fonts = {
  fontSize: {
    default: "16px",
    sm: "13px",
    lg: "18px",
    xl: "22px",
  },
  fontWeight: {
    normal: "400",
    bold: "700",
    extrabold: "900",
  },
  fontFamily: {
    opensans: ['"open san"', "helvetica", "arial", "sans-serif"],
  },
}

export { colors, fonts }
