
// This is a generated file, do not modify manually
import * as React from "react"
import Icon from "@mkt/cp/src/components/Icon";

export default function OutlineClose(props) {
  return (
    <Icon
      iconMarkup={'<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.7955 4.20476C5.35617 3.76541 4.64385 3.76541 4.20451 4.20474C3.76517 4.64408 3.76516 5.35639 4.2045 5.79573L10.4089 12.0002L4.2045 18.2048C3.76516 18.6441 3.76517 19.3564 4.20451 19.7957C4.64385 20.2351 5.35617 20.2351 5.7955 19.7957L11.9999 13.5912L18.2043 19.7957C18.6436 20.2351 19.3559 20.2351 19.7953 19.7957C20.2346 19.3564 20.2346 18.6441 19.7953 18.2048L13.5909 12.0002L19.7953 5.79573C20.2346 5.35639 20.2346 4.64408 19.7953 4.20474C19.3559 3.76541 18.6436 3.76541 18.2043 4.20476L11.9999 10.4092L5.7955 4.20476Z" fill="#0048DB"/></svg>'}
			iconName="OutlineClose"
      {...props}
    />
  );
}
