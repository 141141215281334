import { useEffect } from "react"

import useAnalytics from "@mkt/ui/hooks/useAnalytics"

const useCardContent = (content) => {
	const { form } = useAnalytics()
  
	const name = 'card-referral'
	const type = 'soft-lead'
	const version = 'v1'
	
	useEffect(() => {
	  form.setForm({ name, type, version, brand: content.issuer, id: `ccbr-${name}`})
	}, [])
  
}

export default useCardContent