import { APP_NAME } from "gatsby-env-variables"
import { css } from "@emotion/react"
import { above } from "@mkt/ui/styles/Breakpoints"
import { container } from "@mkt/ui/styles/Functions"

const mastercardFooterFix = css`
  padding-bottom: 0;
`

export const productPageHold = css`
  ${container};
  border: 1px solid red;
`

export const ProductPageTemplate = css`
  padding-bottom: 36px;
  ${APP_NAME === "mastercard" && mastercardFooterFix}

  ${above["l"]} {
    padding-bottom: 24px;
    ${APP_NAME === "mastercard" && mastercardFooterFix}
  }
`
