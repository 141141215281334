import { Text } from "iq-blueberry"
import Img from "@mkt/ui/components/common/Img"
import useLoaderRedirect from "./hook"

import * as S from "./styles"

const LoaderRedirect = ({ data, isOpen, LogoRedirect, imgOptimized = true }) => {
  const image = data?.redirect_image?.url || data?.image_logo?.filename || data?.image?.filename
  const { issuerName } = useLoaderRedirect(data);

  return (
    <div
      css={
        isOpen ? [S.LoaderRedirect, S.LoaderRedirectIsOpen] : S.LoaderRedirect
      }
    >
      <div
        css={
          isOpen
            ? [S.LoaderRedirectHolder, S.LoaderRedirectHolderActive]
            : S.LoaderRedirectHolder
        }
      >
        <div css={S.LoaderRedirectHeader}>
          <div css={S.LoaderRedirectBall}>
            <Img
              src={LogoRedirect}
              alt="Ícone da marca"
              width="23px"
              height="32px"
              optimize={imgOptimized}
            />
          </div>
          <div css={S.LoaderRedirectBar}></div>
          <div css={S.LoaderRedirectBall}>
            {!!image ? (
              <Img
                src={image}
                alt={data?.name}
                width={data?.redirect_image?.width || '32px'}
                height={data?.redirect_image?.height || '32px'}
                optimize={imgOptimized}
              />
            ) : null}
          </div>
        </div>

        <div css={S.LoaderRedirectText}>
          <Text as="p" defaultColor="title" variant="body-small">
            Em instantes você será direcionado para o site do {issuerName || (data?.issuer || data?.partner)}.
          </Text>
          <Text as="p" defaultColor="title" variant="body-small">
            {data?.redirect_text || null}
          </Text>
        </div>
      </div>
    </div>
  )
}

export default LoaderRedirect
