import DefaultLayout from "../../layout/DefaultLayout"
import loadable from "@loadable/component"
import { withFormMarketplace } from "@mkt/firebolt/config"

import Hero from "@mkt/ui/components/Ccbr/Hero"
import ProductPageTemplate from "@mkt/ui/templates/ProductPage"
import Content from "./components/Content"
import Button from "@mkt/ui/components/Ccbr/Button"

const CardsFeatured = loadable(() => import("./components/CardsFeatured"))

const Card = ({ pageContext, location }) => {
  const CcbrLogo =
    "https://assets.iq.com.br/f/114280/x/71468ec321/ccbr-logo-black.svg"
  return (
    <DefaultLayout pageContext={pageContext} location={location}>
      <ProductPageTemplate
        pageContext={pageContext}
        ComponentHero={Hero}
        ComponentContent={Content}
        ComponentCardsFeatured={CardsFeatured}
        ComponentButton={Button}
        LogoRedirect={CcbrLogo}
        type="pdp"
        breadcrumbs={true}
      />
    </DefaultLayout>
  )
}

export default withFormMarketplace(Card)
