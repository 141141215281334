/* globals APP_NAME */
import { useState, useEffect, useCallback } from "react"
import { useFirebolt } from "@iq-firebolt/client"
import useAnalytics from "@mkt/ui/hooks/useAnalytics"
import { navigate } from "gatsby"
import formatMobiUrl from "@mkt/utils/formatMobiUrl"
import getRandomHashMobi from "@mkt/utils/getRandomHashMobi"
import dataHashsMobi from "@mkt/ui/data/mobi-hashs.json"
import { addScrollTracker } from "@mkt/analytics/components/AnalyticsProvider/helpers/scroll"
import { useTrack } from "@mkt/analytics/redline/track"

export const useModalList = ({
  pageContext,
  sortingHatDecision,
  currentPayload,
  handleSetDataSortingHat,
  isOpenSortingHat
}) => {
  const { track } = useTrack()
  const { goNextStep, addRequestsMetadata } = useFirebolt()
  const { anonymousId, dispatchGlobalError, fireboltId } = useAnalytics()

  const [sortingHatData, setSortingHatData] = useState()

  const [isOpenLoaderRedirect, setIsOpenLoaderRedirect] = useState(false)
  const [loaderRedirectData, setLoaderRedirectData] = useState({})

  useEffect(() => {
    if (sortingHatDecision) {
      const sortingHatDataFromDecision = sortingHatDecision?.map((decision) => {
        return pageContext.newSortingHatData.find(
          (shItem) => shItem.slug === decision.slug
        )
      }).filter((decision) => decision)

      setSortingHatData(sortingHatDataFromDecision)
    }
  }, [sortingHatDecision])

  useEffect(() => {
    isOpenSortingHat && (document.body.style.overflow = 'hidden')
    return () => document.body.style.overflow = 'auto'
  }, [isOpenSortingHat])

  const { content } = pageContext

  const { title: chosenCardTitle, product_slug: chosenCardSlug, issuer: chosenCardIssuer } = content

  const jeittoSHDecision = sortingHatDecision?.find(
    (decision) => decision.slug === "jeitto"
  )
  const jeittoValue =
    jeittoSHDecision && jeittoSHDecision.value ? jeittoSHDecision.value : 0

  const handleDispatchGlobalError = ({ formData }) => {
    dispatchGlobalError()
  }

  const hasGenericError = ({ response }) => {
    const getGenericError = response?.errors.find((err) => err.slug === "")

    if (getGenericError?.slug === "") {
      return true
    }

    return false
  }

  const handleRedirectOffer = async (selectedOffer) => {

    const slug = selectedOffer?.slug || selectedOffer?.product_slug
    const partner = selectedOffer?.partner || selectedOffer?.issuer

    setIsOpenLoaderRedirect(true)
    setLoaderRedirectData(selectedOffer)

    const isInitialOffer = slug === chosenCardSlug

    const offerSHPayload = {
      ...currentPayload,
      choosen_card: slug,
      product_slug: chosenCardSlug,
      issuer: partner
    }

    const finalPayload = isInitialOffer ? currentPayload : offerSHPayload

    if (isInitialOffer && content?.integrations_list?.[0]?.card_id) {
      const mobiIntegration = content.integrations_list[0]

      const propsMobi = {
        card_id: mobiIntegration.card_id,
        card_type: mobiIntegration.card_type,
        itau_source_url: formatMobiUrl({
          anonymousId,
          fireboltId,
          cardId: mobiIntegration.card_id,
          cardSlug: currentPayload.product_slug,
          cardType: mobiIntegration.card_type,
          randomHash: getRandomHashMobi(dataHashsMobi),
        }),
      }

      addRequestsMetadata("propsMobiCard", propsMobi)
    }

    handleSetDataSortingHat({
      slug: slug,
      payload: sortingHatData,
      shVersion: "v3",
    })

    const response = await goNextStep(finalPayload)


    if (response?.errors) {
      track('formErrored', {...response, ...finalPayload})
    }

    if (hasGenericError({ response })) {
      return handleDispatchGlobalError({ formData: currentPayload })
    }
  }

  const goBackToChooseAnotherCard = () => {
    const pathToRedirect = APP_NAME === "ccbr" ? "/" : "/cartao-de-credito"

    navigate(pathToRedirect)
  }

  const sendProductViewedEvent = useCallback(() => {
    const isDesktop = window.innerWidth >= 768
    const modalWindowSize = window.innerHeight / 1.4

    if (sortingHatData && content) {
      addScrollTracker({
        product: content,
        productsList: sortingHatData,
        customWindowSize: isDesktop && modalWindowSize,
        location: 'iq-modal-sh',
      })
    }
  }, [sortingHatData, content])

  useEffect(() => sendProductViewedEvent(), [sendProductViewedEvent])

  return {
    sortingHatData,
    chosenCardTitle,
    chosenCardSlug,
    jeittoValue,
    handleRedirectOffer,
    goBackToChooseAnotherCard,
    isOpenLoaderRedirect,
    loaderRedirectData
  }
}
