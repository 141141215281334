import Icon from "@mkt/ui/components/common/Icon";

export default function Padlock({ color = '#1C1C1C', ...props }) {
  return (
    <Icon
      iconMarkup={`<svg viewBox="0 0 16 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <title>Group 5</title> <desc>Created with Sketch.</desc> <g id="Pag-Produto-[Mobile]" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Produto-Desktop" transform="translate(-1230.000000, -321.000000)"> <g id="CTA-Copy-2" transform="translate(1060.000000, 300.000000)"> <g id="Group-5" transform="translate(171.000000, 22.000000)"> <path d="M1.55555556,7.4375 L12.4444444,7.4375 C13.3031111,7.4375 14,8.21640909 14,9.17613636 L14,15.2613636 C14,16.2210909 13.3031111,17 12.4444444,17 L1.55555556,17 C0.696888889,17 0,16.2210909 0,15.2613636 L0,9.17613636 C0,8.21640909 0.696888889,7.4375 1.55555556,7.4375 Z" id="Stroke-1" stroke="${color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <rect id="Rectangle" fill="${color}" x="6" y="10.625" width="2" height="3.1875" rx="1"></rect> <path d="M3,7.4375 L3,4.13194444 C3,1.85028472 4.7912,0 7,0 C9.2096,0 11,1.85028472 11,4.13194444 L11,7.4375" id="Stroke-3" stroke="${color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g> </g> </g></svg>`}
			iconName="Padlock"
      {...props}
    />
  );
}
