import SbEditable from "@mkt/ui/components/common/SbEditable"

import Button from "@mkt/ui/components/Ccbr/Button"
import Img from "@mkt/ui/components/common/Img"
import { Padlock } from "@mkt/ui/icons/Ccbr"

import useBannerCard from "./hooks.js"
import * as S from "./styles.js"

const BannerCard = ({ content }) => {
  const { handleScrollToForm } = useBannerCard(content)
  const { image, title, description, minimum_income: income, annuity } = content
  return (
    <div css={S.cardContent} className="cards-pdp">
      <SbEditable content={content}>
        <div className="cards-content__container">
          <section css={S.bannerCard}>
            <div css={S.bannerCardContent}>
              <div css={S.bannerCardImage}>
                <Img
                  src={image?.filename}
                  alt={title}
                  width="320px"
                  height="198px"
                  loading="eager"
                />
              </div>
              <div css={S.bannerCardInfo}>
                <h1 className="banner-card__info-title">{title}</h1>
                <p className="banner-card__info-subtitle">{description}</p>
              </div>
              <div css={S.bannerCardDetail}>
                <div css={S.bannerCardDetailIncome} className="detail__wrapper">
                  <p className="banner-card__detail-title">
                    Renda mínima: &nbsp;
                  </p>
                  <p className="banner-card__detail-subtitle">{income}</p>
                </div>
                <div
                  css={S.bannerCardDetailAnnuity}
                  className="detail__wrapper"
                >
                  <p className="banner-card__detail-title">Anuidade: &nbsp;</p>
                  <p className="banner-card__detail-subtitle">{annuity}</p>
                </div>
                <Button
                  type="large"
                  iconRight
                  Icon={() => <Padlock color="#fff" />}
                  onClick={handleScrollToForm}
                >
                  PEÇA JÁ!
                </Button>
              </div>
            </div>
          </section>
        </div>
      </SbEditable>
    </div>
  )
}

export default BannerCard
