export const noInteractionAlertData = {
  noInteractionAlertImage: 'https://assets.iq.com.br/f/114280/140x198/dbefadb607/featured-cards-gift.png',
  noInteractionAlertModalImage: 'https://assets.iq.com.br/f/114280/150x193/5f3fa201a2/featured-cards-person.png',
  imageAltBanner: 'Ilustração de uma caixa de presente com um cartão da cor roxa saindo de dentro dela junto com moedas amarelas com o símbolo R$.',
  imageAltModal: 'Ilustração de uma mulher segurando um cartão na mão esquerda e um celular na mão direita, com o símbolo de aprovado na tela. Também há imagens de um avião (indicando uma viagem) e um símbolo % (indicando um desconto).',
  title: 'Confira os cartões em destaque',
  subtitle: 'Você também pode ter os cartões mais pedidos no iq',
  buttonText: 'Saiba mais',
  url: 'https://www.iq.com.br/cartao-de-credito/destaques-do-mes',
  pathnamesToShowBanner: ['/cartao-de-credito/listagem'],
  pathnamesToUseModalOnly: [/iq.com.br*\/cartao-de-credito\//],
  bannerBeforeIndex: 2,
  timeToShow: 120000,
}