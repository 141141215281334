import { useEffect, useState } from "react";
import StoryblokClient from "storyblok-js-client";
const { STORYBLOK_ACCESS_TOKEN } = require("gatsby-env-variables");

const sbClient = new StoryblokClient({
  accessToken: STORYBLOK_ACCESS_TOKEN,
  cache: {
    clear: "auto",
    type: "memory",
  },
})

const useStoryblokDimensions = (slug) => {
  const [dimensions, setDimensions] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDimensions = async () => {
      try {
        const { data } = await sbClient.get(`cdn/datasource_entries?datasource=${slug}`);
        const { datasource_entries } = data;
        setDimensions(datasource_entries);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchDimensions();
  }, [slug]);

  return dimensions;
};

export default useStoryblokDimensions;
