import OutlineClose from "@mkt/cp/src/components/icons/generated/outline/OutlineCloseLight"

import useModal from "./hooks"
import * as S from "./styles"

const Modal = ({children, openOnStart = true, useTimerToOpen = false, timeToOpenModal = 0, showCloseButton=true, containerCss={}}) => {
  const {
    isOpen,
    openModal,
    closeModal,
    removeTimeout,
  } = useModal({openOnStart, useTimerToOpen, timeToOpenModal})
  return (
    <div
      css={isOpen ? [S.Modal, S.ModalActive, containerCss] : [S.Modal, containerCss]}
    >
      <div css={S.Overlay}></div>
      <div css={S.ContentWrapper}>
        {showCloseButton &&
          <div css={S.ContentClose}>
            <div onClick={closeModal}>
              <OutlineClose />
            </div>
          </div>
        }
        {children}
      </div>
    </div>
  )
}

export default Modal
